import { groupRiskQuestions } from '@inyova/grow';

import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as InyovaGrowActions from './inyova-grow.actions';
import * as AccountActions from '@account/account.actions';

import {
  GrowFund,
  GrowPortfolioAllocation,
  GrowProject,
  InyovaGrowAccount,
  InyovaGrowAccountStatus,
  InyovaGrowPercentages,
  GrowProjectStatus,
  InyovaGrowRisk
} from '@app/shared/models/Grow';
import { State } from '@shared/models/State';

export const GROW_REDUCER_FEATURE_KEY = 'grow';

export interface GrowState {
  growAccount: InyovaGrowAccount;
  growProjects: { [key: string]: GrowProject };
  growProjectsIds: string[];
  growProjectsLoading: boolean;
  growProjectsLoaded: boolean;
  growPortfolioAllocation: null | GrowPortfolioAllocation;
  growFund: null | GrowFund;
  growRisk: null | InyovaGrowRisk;
  growPercentages: null | InyovaGrowPercentages;
  growRiskLoading: boolean;
  growRiskLoaded: boolean;
  growCheckLoading: boolean;
  growCheckLoaded: boolean;
  growSubmitLoading: boolean;
  growIntroLoading: boolean;
  growInvestmentLoading: boolean;
  growInvestmentDone: boolean;
  growInvestmentFinishStatus: 'success' | 'reached' | '';
}

export const initialState: GrowState = {
  growAccount: {
    id: '',
    currency: 'CHF',
    customer_id: 0,
    fees_consent: false,
    status: null,
    initial_investment: null,
    monthly_investment: null,
    projects_percentage: null,
    portfolio_value: null,
    portfolio_value_chf: null,
    portfolio_value_eur: null,
    portfolio_value_eur_chf: null,
    combined_expected_return: null,
    minimum_investment: 2000,
    iban: '',
    investment_risk_consent: false,
    customer_name: '',
    reporting_timestamp: '',
    inyova_and_custody_fees: null,
    product_costs: null,
    edit_risk_profile: null,
    eur_exchange_rate: 0
  },
  growProjects: {},
  growProjectsIds: [],
  growProjectsLoading: false,
  growProjectsLoaded: false,
  growPortfolioAllocation: null,
  growFund: null,
  growRisk: null,
  growPercentages: null,
  growRiskLoading: false,
  growRiskLoaded: false,
  growCheckLoading: false,
  growCheckLoaded: false,
  growSubmitLoading: false,
  growIntroLoading: false,
  growInvestmentLoading: false,
  growInvestmentDone: false,
  growInvestmentFinishStatus: ''
};

const growReducer = createReducer(
  initialState,
  on(InyovaGrowActions.getInyovaGrowAccountSuccess, (state, action) => ({
    ...state,
    growAccount: action.data
  })),
  on(InyovaGrowActions.createInyovaGrowAccount, (state, action) => ({
    ...state,
    growIntroLoading: true
  })),
  on(InyovaGrowActions.createInyovaGrowAccountSuccess, (state, action) => ({
    ...state,
    growAccount: action.data,
    growIntroLoading: false
  })),
  on(InyovaGrowActions.createInyovaGrowAccountFail, (state) => ({ ...state, growAccount: null, growIntroLoading: false })),
  on(InyovaGrowActions.getGrowIbanSuccess, (state, action) => ({
    ...state,
    growAccount: { ...state.growAccount, iban: action.iban }
  })),
  on(InyovaGrowActions.getGrowProjects, (state) => ({
    ...state,
    growProjectsLoading: true
  })),
  on(InyovaGrowActions.getGrowProjectsSuccess, (state, { projects, money_market_fund }) => {
    const growProjectsIds: string[] = [];
    const growProjects = projects.reduce((entities: { [key: string]: GrowProject }, project) => {
      growProjectsIds.push(project.id);
      return {
        ...entities,
        [project.id]: project
      };
    }, {});

    return {
      ...state,
      growProjects,
      growProjectsIds,
      growFund: money_market_fund,
      growProjectsLoading: false,
      growProjectsLoaded: true
    };
  }),
  on(InyovaGrowActions.getGrowProjectsFail, (state) => {
    return {
      ...state,
      growProjectsLoading: false,
      growProjectsLoaded: false
    };
  }),
  on(AccountActions.changeLanguage, (state) => ({
    ...state,
    growProjectsLoaded: false
  })),
  on(InyovaGrowActions.getGrowPortfolioAllocationSuccess, (state, { growPortfolioAllocation }) => ({
    ...state,
    growPortfolioAllocation
  })),
  on(InyovaGrowActions.markGrowProjectSuccess, (state, { projectId, investment }) => {
    return {
      ...state,
      growProjects: {
        ...state.growProjects,
        [projectId]: {
          ...state.growProjects[projectId],
          investments: [investment]
        }
      }
    };
  }),
  on(InyovaGrowActions.unmarkGrowProjectSuccess, (state, { projectId }) => {
    return {
      ...state,
      growProjects: {
        ...state.growProjects,
        [projectId]: {
          ...state.growProjects[projectId],
          investments: []
        }
      }
    };
  }),
  on(InyovaGrowActions.getGrowRisk, (state) => {
    return {
      ...state,
      growRiskLoading: true
    };
  }),
  on(InyovaGrowActions.getGrowRiskSuccess, (state, { data, percentages }) => {
    return {
      ...state,
      growRisk: data,
      growPercentages: percentages,
      growRiskLoading: false,
      growRiskLoaded: true,
      growCheckLoaded: state.growAccount.projects_percentage !== null
    };
  }),
  on(InyovaGrowActions.getGrowRiskFail, (state) => {
    return {
      ...state,
      growRiskLoading: false,
      growRiskLoaded: false
    };
  }),
  on(InyovaGrowActions.checkGrowRiskProfile, (state) => {
    return {
      ...state,
      growCheckLoading: true
    };
  }),
  on(InyovaGrowActions.checkGrowRiskProfileSuccess, (state, { data }) => {
    return {
      ...state,
      growPercentages: data,
      growCheckLoading: false,
      growCheckLoaded: true
    };
  }),
  on(InyovaGrowActions.checkGrowRiskProfileFail, (state) => {
    return {
      ...state,
      growCheckLoading: false,
      growCheckLoaded: false
    };
  }),
  on(InyovaGrowActions.updateInyovaGrowRisk, (state) => ({
    ...state,
    growSubmitLoading: true,
    ...(state.growAccount.status === InyovaGrowAccountStatus.FUNDED && { growProjectsLoaded: false })
  })),
  on(InyovaGrowActions.updateInyovaGrowRiskSuccess, (state, { data }) => {
    const { interest_account, ...risk } = data;

    return {
      ...state,
      growSubmitLoading: false,
      growAccount: {
        ...state.growAccount,
        ...interest_account
      },
      growRisk: {
        ...state.growRisk,
        ...risk
      },
      growCheckLoaded: interest_account.projects_percentage !== null
    };
  }),
  on(InyovaGrowActions.updateInyovaGrowRiskFail, (state) => ({
    ...state,
    growSubmitLoading: false
  })),
  on(InyovaGrowActions.finishGrowProjectInvestment, (state) => ({
    ...state,
    growInvestmentLoading: true
  })),
  on(InyovaGrowActions.finishGrowProjectInvestmentSuccess, (state, { projectId, amount }) => ({
    ...state,
    growInvestmentDone: true,
    growInvestmentFinishStatus: 'success' as const,
    growInvestmentLoading: false,
    growProjectsLoaded: false,
    growPortfolioAllocation: null,
    growAccount: { ...state.growAccount, edit_risk_profile: false },
    growProjects: {
      ...state.growProjects,
      [projectId]: {
        ...state.growProjects[projectId],
        investments:
          state.growProjects[projectId].investments[0].status === GrowProjectStatus.INTERESTED
            ? [{ ...state.growProjects[projectId].investments[0], status: GrowProjectStatus.COMMITTED }]
            : [...state.growProjects[projectId].investments, { amount, status: GrowProjectStatus.COMMITTED }]
      }
    } as { [key: string]: GrowProject }
  })),
  on(InyovaGrowActions.finishGrowProjectInvestmentReached, (state) => ({
    ...state,
    growInvestmentDone: true,
    growInvestmentFinishStatus: 'reached' as const,
    growInvestmentLoading: false,
    growProjectsLoaded: false
  })),
  on(InyovaGrowActions.finishGrowProjectInvestmentFail, InyovaGrowActions.finishGrowProjectInvestmentReset, (state) => ({
    ...state,
    growInvestmentDone: false,
    growInvestmentSuccess: false,
    growInvestmentFinishStatus: '' as const,
    growInvestmentLoading: false
  })),
  // NgRx store clean up after logout
  on(AccountActions.customerLogout, () => ({ ...initialState })),
  on(AccountActions.customerLogoutAndUnpairDevice, () => ({ ...initialState }))
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function reducer(state: GrowState | undefined, action: Action) {
  return growReducer(state, action);
}

/*
 * SELECTORS
 * */
export const selectFeature = (state: State) => state.grow;
// Account reducer selectors
export const selectInyovaGrowAccount = createSelector(selectFeature, (state: GrowState) => state.growAccount);
export const selectInyovaGrowAccountID = createSelector(selectInyovaGrowAccount, (account: InyovaGrowAccount) => account && account.id);
export const selectInyovaGrowAccountStatus = createSelector(selectInyovaGrowAccount, (account: InyovaGrowAccount) => account.status);
export const selectInyovaGrowIntroLoading = createSelector(selectFeature, (state: GrowState) => state.growIntroLoading);

export const selectGrowProjectsLoaded = createSelector(selectFeature, (state: GrowState) => state.growProjectsLoaded);
export const selectInyovaGrowPortfolioAllocation = createSelector(selectFeature, (state: GrowState) => state.growPortfolioAllocation);

export const selectGrowProjects = createSelector(selectFeature, (state: GrowState) => state.growProjects);
export const selectGrowProjectsIds = createSelector(selectFeature, (state: GrowState) => state.growProjectsIds);

export const selectGrowProjectById = (id: string) =>
  createSelector(selectGrowProjects, (entities) => {
    return entities[id];
  });
export const selectInyovaGrowFund = createSelector(selectFeature, (state: GrowState) => state.growFund);
export const selectHasSelectedProject = createSelector(selectGrowProjects, (entities) => {
  return Object.values(entities).some((project) => project.investments.some((investment) => investment.status === GrowProjectStatus.INTERESTED));
});

export const selectInyovaGrowRiskLoaded = createSelector(selectFeature, (state: GrowState) => state.growRiskLoaded);
export const selectGrowRisk = createSelector(selectFeature, (state) => state.growRisk);
export const selectGrowOnboardingId = createSelector(selectGrowRisk, (state) => state.id);
export const selectInyovaGrowRisk = createSelector(selectGrowRisk, selectInyovaGrowAccount, (growRisk, growAccount) => {
  if (growRisk === null) {
    return { growAccount, surveyGroups: null, financialDetail: null };
  }
  return {
    growAccount,
    surveyGroups: groupRiskQuestions(growRisk.risk_survey.survey_questions),
    financialDetail: growRisk.financial_detail
  };
});
export const selectInyovaGrowPercentages = createSelector(selectFeature, (state) => state.growPercentages);

export const selectInyovaGrowCheckLoading = createSelector(selectFeature, (state: GrowState) => state.growCheckLoading);
export const selectInyovaGrowCheckLoaded = createSelector(selectFeature, (state: GrowState) => state.growCheckLoaded);
export const selectInyovaGrowSubmitLoading = createSelector(selectFeature, (state: GrowState) => state.growSubmitLoading);
export const selectInyovaGrowInvestmentLoading = createSelector(selectFeature, (state: GrowState) => state.growInvestmentLoading);
export const selectInyovaGrowInvestmentDone = createSelector(selectFeature, (state: GrowState) => state.growInvestmentDone);
export const selectInyovaGrowFinishStatus = createSelector(selectFeature, (state: GrowState) => state.growInvestmentFinishStatus);
